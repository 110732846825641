// eslint-disable-next-line import/no-extraneous-dependencies
import Mustache from 'mustache'
import german from 'shared/src/resource/translation/de.json'
import english from 'shared/src/resource/translation/en.json'
import french from 'shared/src/resource/translation/fr.json'
import { LanguageCode, getLanguageConfig, getDeviceBrandName, getHardwareName } from 'shared/src/util/language'

export const translations: Record<LanguageCode, Record<string, string>> = {
  [LanguageCode.GERMAN]: german,
  [LanguageCode.ENGLISH]: english,
  [LanguageCode.FRENCH]: french,
}

const { langCode } = getLanguageConfig()
const deviceBrandName = getDeviceBrandName()
const hardwareName = getHardwareName()

const createTranslations = (key: string): string => {
  try {
    const translationLang = translations[langCode]
    const rawTranslation = translationLang[key]
    return Mustache.render(rawTranslation, { deviceBrandName, hardwareName })
  } catch (error: any) {
    console.error(`Translation not found for ${key}`, error.message)
    return `| no translation found for ${key} |`
  }
}

export const getBooleanTranslationKey = (value: boolean): string => (value ? 'yes' : 'no')

export const T = (translationKey: string) => createTranslations(translationKey)
