import type { ReactNode } from 'react'

export type ChildrenProps = { children?: ReactNode }

export type DtoQuantity<T> = {
  value: T
  unit: Unit
}

export enum Unit {
  NONE = '',
  LITER = 'L',
  KILOGRAM = 'kg',
  PERCENT = '%',
  METER = 'm',
  KILOMETER = 'km',
  CENTIMETER = 'cm',
  MILLIMETER = 'mm',
  PASCAL = 'Pa',
  CELSIUS = '°C',
}

export enum BatteryLevel {
  FULL = 'FULL',
  GOOD = 'GOOD',
  MEDIUM = 'MEDIUM',
  WARNING = 'WARNING',
  CRITICAL = 'CRITICAL',
}

export enum VolumeDisplayType {
  USABLE_VOLUME = 'USABLE_VOLUME',
  TOTAL_VOLUME = 'TOTAL_VOLUME',
}

export type DtoLocalDate = {
  year: number
  month: number
  day: number
}

export enum AppFlavor {
  FOXMOBILE = 'foxmobile',
  PELLETTRACKER = 'pellettracker',
}

export type DtoPaginatedResult<T> = {
  page: number
  pageSize: number
  totalPages: number
  totalRows: number
  data: T[]
}

export type DtoOffsetResult<T> = {
  offset: number
  total: number
  data: T[]
}

export type ComputedStorage = {
  usableQuantity: DtoQuantity<number>
  totalQuantity: DtoQuantity<number>
  productSafetyMargin: number
  productName: string
  isCollectingProduct: boolean
  displayAs100Percent: VolumeDisplayType
}

export type JwtDto = {
  token_type: string
  access_token: string
  refresh_token: string
}
