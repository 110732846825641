import { FormControl, FormHelperText, FormLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import type { Dispatch } from 'react'
import { ImageUpload } from 'shared/src/components/imageUpload/ImageUpload'
import { FileSizes } from 'shared/src/components/imageUpload/types'
import { useAnalytics } from 'shared/src/context/AnalyticsContext'
import { nullableBooleanStringToBoolean } from 'shared/src/util/booleanUtil'
import { T } from 'shared/src/util/translation'
import { getEnumKeyByValue } from 'shared/src/util/typeChecks'
import { isValue } from 'shared/src/util/typeGuard'
import { LocalStorage } from 'support_form/src/localStorage/LocalStorage'
import { Category, categoryTranslationMap } from 'support_form/src/model/common'
import type { SupportFormState } from 'support_form/src/pages/support/model/SupportFormState'
import type { SupportFormStateAction } from 'support_form/src/pages/support/model/SupportFormStateReducer'
import { SupportFormStateActionType } from 'support_form/src/pages/support/model/SupportFormStateReducer'

interface Props {
  supportFormState: SupportFormState
  dispatchUpdateSupportFormStateAction: Dispatch<SupportFormStateAction>
}

export const SupportForm = (props: Props) => {
  const { AnalyticsEvent } = useAnalytics()
  const { supportFormState, dispatchUpdateSupportFormStateAction } = props

  const writeLocalStorageState = () => {
    LocalStorage.writeSupportForm(supportFormState.hwid, supportFormState.toLocalStorageType())
  }

  return (
    <div className='with-margin'>
      <Typography className='vertical-margin' variant='h4' component='div'>
        {T('submit_support_ticket')}
      </Typography>
      <FormControl className='form-input' fullWidth>
        <FormLabel id='input-user-email-label-id' htmlFor='input-user-email' focused={false}>
          {T('user_email_label')} *
        </FormLabel>
        <TextField
          id='input-user-email'
          required
          fullWidth
          value={supportFormState.userEmail.value ?? ''}
          onChange={(event) =>
            dispatchUpdateSupportFormStateAction({
              type: SupportFormStateActionType.SET_USER_EMAIL,
              payload: { userEmail: event.target.value },
            })
          }
          onBlur={() => {
            dispatchUpdateSupportFormStateAction({ type: SupportFormStateActionType.VALIDATE_USER_EMAIL })
            writeLocalStorageState()
          }}
          error={!supportFormState.userEmail.isValid()}
          helperText={supportFormState.userEmail.getFirstValidationError()}
        />
      </FormControl>

      <FormControl className='form-input' fullWidth>
        <FormLabel id='select-label-category' focused={false}>
          {T('category_label')} *
        </FormLabel>
        <Select
          labelId='select-label-category'
          id='select-category'
          value={String(supportFormState.category.value)}
          onChange={(event) => {
            const category = getEnumKeyByValue<Category>(Category, event.target.value)
            if (isValue(category)) {
              AnalyticsEvent('Category select', category)
              dispatchUpdateSupportFormStateAction({
                type: SupportFormStateActionType.SET_CATEGORY,
                payload: { category },
              })
            }
          }}
          onBlur={() => {
            dispatchUpdateSupportFormStateAction({ type: SupportFormStateActionType.VALIDATE_CATEGORY })
            writeLocalStorageState()
          }}
          error={!supportFormState.category.isValid()}
        >
          <MenuItem value={Category.WRONG_FILL_LEVEL_MEASURED}>
            {T(categoryTranslationMap[Category.WRONG_FILL_LEVEL_MEASURED])}
          </MenuItem>
          <MenuItem value={Category.DEVICE_IS_OFFLINE}>
            {T(categoryTranslationMap[Category.DEVICE_IS_OFFLINE])}
          </MenuItem>
          <MenuItem value={Category.TANK_SETUP}>{T(categoryTranslationMap[Category.TANK_SETUP])}</MenuItem>
          <MenuItem value={Category.CONNECTIVITY_OF_THE_DEVICE}>
            {T(categoryTranslationMap[Category.CONNECTIVITY_OF_THE_DEVICE])}
          </MenuItem>
          <MenuItem value={Category.DATA_LOSS}>{T(categoryTranslationMap[Category.DATA_LOSS])}</MenuItem>
          <MenuItem value={Category.DEVICE_INSTALLATION_ON_THE_TANK}>
            {T(categoryTranslationMap[Category.DEVICE_INSTALLATION_ON_THE_TANK])}
          </MenuItem>
          <MenuItem value={Category.OTHER_ISSUES}>{T(categoryTranslationMap[Category.OTHER_ISSUES])}</MenuItem>
        </Select>
        {isValue(supportFormState.category.isValid()) ? (
          <FormHelperText
            error={!supportFormState.category.isValid()}
            title={supportFormState.category.getFirstValidationError()}
          >
            {supportFormState.category.getFirstValidationError()}
          </FormHelperText>
        ) : null}
      </FormControl>

      {supportFormState.category.value === Category.WRONG_FILL_LEVEL_MEASURED ? (
        <FormControl className='form-input' fullWidth>
          <FormLabel id='select-label-refill' focused={false}>
            {T('recent_refill_label')} *
          </FormLabel>
          <Select
            labelId='select-label-refill'
            id='select-refill'
            value={String(supportFormState.refill.value)}
            onChange={(event) => {
              const refill = nullableBooleanStringToBoolean(event.target.value)
              if (isValue(refill)) {
                AnalyticsEvent('Recent refill', String(refill))
                dispatchUpdateSupportFormStateAction({
                  type: SupportFormStateActionType.SET_REFILL,
                  payload: { refill },
                })
              }
            }}
            onBlur={() => {
              dispatchUpdateSupportFormStateAction({ type: SupportFormStateActionType.VALIDATE_REFILL })
              writeLocalStorageState()
            }}
            error={!supportFormState.refill.isValid()}
          >
            <MenuItem value='true'>{T('yes')}</MenuItem>
            <MenuItem value='false'>{T('no')}</MenuItem>
          </Select>
          {isValue(supportFormState.refill.isValid()) ? (
            <FormHelperText
              error={!supportFormState.refill.isValid()}
              title={supportFormState.refill.getFirstValidationError()}
            >
              {supportFormState.refill.getFirstValidationError()}
            </FormHelperText>
          ) : null}
        </FormControl>
      ) : null}

      {supportFormState.category.value === Category.WRONG_FILL_LEVEL_MEASURED ? (
        <FormControl className='form-input' fullWidth>
          <FormLabel htmlFor='input-fill-level' focused={false}>
            {T('expected_fill_level_label')} *
          </FormLabel>
          <TextField
            id='input-fill-level'
            required
            fullWidth
            value={supportFormState.fillLevel.value ?? ''}
            onChange={(event) =>
              dispatchUpdateSupportFormStateAction({
                type: SupportFormStateActionType.SET_FILL_LEVEL,
                payload: { fillLevel: event.target.value },
              })
            }
            onBlur={() => {
              dispatchUpdateSupportFormStateAction({ type: SupportFormStateActionType.VALIDATE_FILL_LEVEL })
              writeLocalStorageState()
            }}
            error={!supportFormState.fillLevel.isValid()}
            helperText={supportFormState.fillLevel.getFirstValidationError()}
          />
        </FormControl>
      ) : null}

      {supportFormState.category.value === Category.DEVICE_IS_OFFLINE ? (
        <FormControl className='form-input' fullWidth>
          <FormLabel id='select-label-relocated' focused={false}>
            {T('location_changed_label')}
          </FormLabel>
          <Select
            labelId='select-label-relocated'
            id='select-relocated'
            value={String(supportFormState.relocated.value)}
            onChange={(event) => {
              const relocated = nullableBooleanStringToBoolean(event.target.value)
              if (isValue(relocated)) {
                AnalyticsEvent('Location changed', String(relocated))
                dispatchUpdateSupportFormStateAction({
                  type: SupportFormStateActionType.SET_RELOCATED,
                  payload: { relocated },
                })
              }
            }}
            onBlur={() => {
              dispatchUpdateSupportFormStateAction({ type: SupportFormStateActionType.VALIDATE_RELOCATED })
              writeLocalStorageState()
            }}
            error={!supportFormState.relocated.isValid()}
          >
            <MenuItem value='true'>{T('yes')}</MenuItem>
            <MenuItem value='false'>{T('no')}</MenuItem>
          </Select>
          {isValue(supportFormState.relocated.isValid()) ? (
            <FormHelperText
              error={!supportFormState.relocated.isValid()}
              title={supportFormState.relocated.getFirstValidationError()}
            >
              {supportFormState.relocated.getFirstValidationError()}
            </FormHelperText>
          ) : null}
        </FormControl>
      ) : null}
      <FormControl className='form-input' fullWidth>
        <FormLabel htmlFor='input-desctiption' focused={false}>
          {T('description_label')} *
        </FormLabel>
        <TextField
          id='input-desctiption'
          required
          multiline
          rows={4}
          placeholder={T('description_placeholder')}
          fullWidth
          value={supportFormState.description.value ?? ''}
          onChange={(event) =>
            dispatchUpdateSupportFormStateAction({
              type: SupportFormStateActionType.SET_DESCRIPTION,
              payload: { description: event.target.value },
            })
          }
          onBlur={() => {
            dispatchUpdateSupportFormStateAction({ type: SupportFormStateActionType.VALIDATE_DESCRIPTION })
            writeLocalStorageState()
          }}
          error={!supportFormState.description.isValid()}
          helperText={supportFormState.description.getFirstValidationError()}
        />
      </FormControl>

      <Typography variant='body1' component='div'>
        {T('pictures_label')}
      </Typography>
      <ImageUpload
        images={supportFormState.images}
        maxFileSizeByte={FileSizes.SIZE_512_KB}
        updateFormState={(values) => {
          AnalyticsEvent('Images selected', String(values.length))
          dispatchUpdateSupportFormStateAction({
            type: SupportFormStateActionType.SET_IMAGES,
            payload: { images: values },
          })
        }}
      />
    </div>
  )
}
