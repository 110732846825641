import type { ValidatorFunction, ValidationResult } from 'shared/src/model/validator'
import { Regex } from 'shared/src/util/regex'
import { T } from 'shared/src/util/translation'
import { isEmptyTrimmedString, isValue } from 'shared/src/util/typeGuard'
import type { Category } from 'support_form/src/model/common'

const isValueInIntRange = (integer: number) => integer >= -2147483648 && integer <= 2147483647

type ValidatorFunctionCreator<T, S> = (condition: T) => ValidatorFunction<S>

export class Validator {
  static NumberOrUndefined = class {
    static notEmpty: ValidatorFunction<number | undefined> = (value, errorMsg = T('validator_notEmpty')) =>
      isValue(value) ? { valid: true } : { valid: false, error: errorMsg }

    static inIntNumberRange: ValidatorFunction<number | undefined> = (
      value: number | undefined,
      errorMsg = T('validator_number'),
    ) => {
      if (!isValue(value)) return { valid: true }
      return isValueInIntRange(value)
        ? { valid: true }
        : {
            valid: false,
            error: errorMsg,
          }
    }

    static greaterThan: ValidatorFunctionCreator<number, number | undefined> =
      (lowerLimit: number) =>
      (
        value: number | undefined,
        errorMsg = `${T('validator_number_greaterThan')} ${lowerLimit.toString()}`,
      ): ValidationResult => {
        if (!isValue(value)) return { valid: true }
        return value > lowerLimit ? { valid: true } : { valid: false, error: errorMsg }
      }
  }

  static String = class {
    static notEmpty: ValidatorFunction<string> = (value: string, errorMsg = T('validator_notEmpty')) =>
      isValue(value) && value.trim().length > 0 ? { valid: true } : { valid: false, error: errorMsg }

    static number: ValidatorFunction<string> = (value: string, errorMsg = T('validator_number')) => {
      const numValue = Number(value)
      return Regex.number.test(value) && isValueInIntRange(numValue)
        ? { valid: true }
        : {
            valid: false,
            error: errorMsg,
          }
    }

    static email: ValidatorFunction<string> = (value, errorMsg = T('validator_email')) =>
      isEmptyTrimmedString(value) || Regex.email.test(value)
        ? { valid: true }
        : {
            valid: false,
            error: errorMsg,
          }

    static minLength: ValidatorFunctionCreator<number, string> =
      (lowerLimit: number) =>
      (value: string, errorMsg = T('validator_minLength')): ValidationResult =>
        isValue(value) && value.length >= lowerLimit ? { valid: true } : { valid: false, error: errorMsg }

    static isHwid: ValidatorFunction<string> = (value: string, errorMsg = T('validator_hwid')): ValidationResult =>
      isValue(value) && (Regex.hwid.test(value) || Regex.hwidDemo.test(value))
        ? { valid: true }
        : {
            valid: false,
            error: errorMsg,
          }

    static maxLength: ValidatorFunctionCreator<number, string> =
      (upperLimit: number) =>
      (value: string, errorMsg = T('validator_maxLength')): ValidationResult =>
        isValue(value) && value.length <= upperLimit ? { valid: true } : { valid: false, error: errorMsg }
  }

  static StringOrUndefined = class {
    static notEmpty: ValidatorFunction<string | undefined> = (
      value: string | undefined,
      errorMsg = T('validator_notEmpty'),
    ) => (isValue(value) && value.trim().length > 0 ? { valid: true } : { valid: false, error: errorMsg })

    static undefinedOrNotEmpty: ValidatorFunction<string | undefined> = (
      value: string | undefined,
      errorMsg = T('validator_notEmpty'),
    ) => {
      if (!isValue(value)) return { valid: true }
      return value.trim().length > 0 ? { valid: true } : { valid: false, error: errorMsg }
    }

    static email: ValidatorFunction<string | undefined> = (value, errorMsg = T('validator_email')) => {
      if (!isValue(value)) return { valid: true }
      return Validator.String.email(value, errorMsg)
    }

    static minLength: ValidatorFunctionCreator<number, string | undefined> =
      (lowerLimit: number) =>
      (value: string | undefined, errorMsg = T('validator_minLength')): ValidationResult => {
        if (!isValue(value)) return { valid: true }
        return value.length >= lowerLimit ? { valid: true } : { valid: false, error: errorMsg }
      }
  }

  static BooleanOrUndefined = class {
    static notEmpty: ValidatorFunction<boolean | undefined> = (value, errorMsg = T('validator_notEmpty')) =>
      isValue(value) ? { valid: true } : { valid: false, error: errorMsg }
  }

  static CategoryOrUndefined = class {
    static notEmpty: ValidatorFunction<Category | undefined> = (value, errorMsg = T('validator_notEmpty')) =>
      isValue(value) ? { valid: true } : { valid: false, error: errorMsg }
  }

  public static run = (validators: ValidatorFunction<string>[], value: string): ValidationResult[] =>
    validators.map((it) => it(value))
}
