import { useMemo } from 'react'

export const useUrlSearchParams = () => {
  const { location } = window

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  return {
    urlSearchParams,
  }
}
