/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as Sentry from '@sentry/react'
import { Regex } from 'shared/src/util/regex'
import { isValue, isValueAndNotEmptyTrimmedString } from 'shared/src/util/typeGuard'

export type AnalyticsUserInfo = {
  user_id: string
  user_role: string
  user_language?: string
  user_company_position?: string
  partner_id?: string
  partner_name?: string
  impersonator?: string
  environment?: string
  accounting_partner_id?: string
  accounting_partner_name?: string
}

const localUseScript = 'https://scripts.simpleanalyticscdn.com/latest.dev.js'
const cloudUseScript = 'https://simple.foxinsights.ai/latest.js'
export class SimpleAnalytics {
  private host: string
  public isInitialized: boolean
  private userInfo?: AnalyticsUserInfo
  private analyticsClientId?: string
  private isDeployed = ['foxinsights', 'foxmobile'].some((it) => window.location.hostname.indexOf(it) > -1)
  constructor() {
    this.isInitialized = false
    this.host = this.getSimpleAnalyticsHost()
    this.userInfo = undefined
    this.analyticsClientId = undefined
  }

  private getSimpleAnalyticsHost() {
    const { hostname } = window.location
    if (['storage.foxinsights.ai', 'whatsinside.foxinsights.ai'].includes(hostname)) {
      return 'storage.foxinsights.ai'
    }
    if (hostname === 'installation-images.foxinsights.ai' || hostname === 'support.foxmobile.app') {
      return hostname
    }
    return ''
  }

  private initSimpleAnalytics() {
    const pathOverriderName = 'pathOverrider'
    const saPathOverrider = document.createElement('script')
    saPathOverrider.innerHTML = `
    function ${pathOverriderName}({path}) {
      return path.replace(${Regex.uuid}, "{{UUID}}").replace(${Regex.hwid}, "{{HWID}}")
    }
    `.trim()
    document.body.appendChild(saPathOverrider)

    const saScript = document.createElement('script')
    saScript.src = this.isDeployed ? cloudUseScript : localUseScript
    saScript.async = true
    saScript.defer = true
    saScript.setAttribute('data-path-overwriter', pathOverriderName)
    saScript.setAttribute('data-hostname', this.host)
    document.body.appendChild(saScript)
  }

  identify(userInfo: AnalyticsUserInfo) {
    this.userInfo = userInfo
  }

  init(analyticsClientId: string) {
    if (!this.isInitialized && isValueAndNotEmptyTrimmedString(this.host)) {
      this.initSimpleAnalytics()
      this.isInitialized = true
      this.analyticsClientId = analyticsClientId
    }
  }

  reset() {
    this.isInitialized = false
  }

  logEvent(action: string, metaData: { category: string; label?: string; value?: number; nonInteraction?: boolean }) {
    if (this.isInitialized) {
      if (isValue(window.sa_event)) {
        window.sa_event(action, {
          ...metaData,
          ...this.userInfo,
          analyticsClientId: this.analyticsClientId,
        })
      } else {
        Sentry.captureException(new Error('Simple Analytics not initialized'))
      }
    }
  }
}
export const simpleAnalytics = new SimpleAnalytics()
