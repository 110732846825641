import { ThemeProvider } from '@mui/material'
import * as Sentry from '@sentry/react'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useCallback, type PropsWithChildren } from 'react'
import { AnalyticsContextProvider } from 'shared/src/context/AnalyticsContext'
import { muiTheme } from 'shared/src/theme/mui-theme'
import type { ANALYTICS_CATEGORY } from 'shared/src/util/trackingHelpers'
import { isValue } from 'shared/src/util/typeGuard'

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (err) => Sentry.captureException(err),
  }),
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      onError: (err) => Sentry.captureException(err),
    },
  },
})

type Props = {
  analyticsCategory?: ANALYTICS_CATEGORY
}

export const WrapperProvider = ({ analyticsCategory, children }: PropsWithChildren<Props>) => {
  const ThemeWrapper = useCallback(() => {
    return (
      <ThemeProvider theme={muiTheme}>
        <QueryClientProvider client={queryClient}>
          {children}
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    )
  }, [children])

  return (
    <>
      {isValue(analyticsCategory) ? (
        <AnalyticsContextProvider category={analyticsCategory}>
          <ThemeWrapper />
        </AnalyticsContextProvider>
      ) : (
        <ThemeWrapper />
      )}
    </>
  )
}
