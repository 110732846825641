import { Button, Typography } from '@mui/material'
import type { Reducer } from 'react'
import { useEffect, useMemo, useReducer, useState } from 'react'
import { FoxIconCaution } from 'shared/src/components/font/FoxIcon'
import { useAnalytics } from 'shared/src/context/AnalyticsContext'
import type { AppFlavor } from 'shared/src/model/common'
import { T } from 'shared/src/util/translation'
import { getEnumKeyByValue } from 'shared/src/util/typeChecks'
import { isValue } from 'shared/src/util/typeGuard'
import { LocalStorage } from 'support_form/src/localStorage/LocalStorage'
import { URLQueryKeys, Category } from 'support_form/src/model/common'
import { SupportForm } from 'support_form/src/pages/support/SupportForm'
import { SupportHeader } from 'support_form/src/pages/support/SupportHeader'
import { SupportPartnerInfo } from 'support_form/src/pages/support/SupportPartnerInfo'
import { SupportSelectedDevice } from 'support_form/src/pages/support/SupportSelectedDevice'
import { SupportFormState } from 'support_form/src/pages/support/model/SupportFormState'
import type { SupportFormStateAction } from 'support_form/src/pages/support/model/SupportFormStateReducer'
import {
  SupportFormStateActionType,
  supportFormStateReducer,
} from 'support_form/src/pages/support/model/SupportFormStateReducer'
import { useMutationSendSupportEmail } from 'support_form/src/pages/support/queries/useMutationSendSupportEmail'
import { useQueryGetSetupInfo } from 'support_form/src/pages/support/queries/useQueryGetSetupInfo'
import { useUrlSearchParams } from 'support_form/src/util/hook'
import { Validator } from 'support_form/src/util/validator'

interface SupportFormContainerProps {
  hwid: string
  appFlavor: AppFlavor
  setSuccessState: () => void
}

interface ErrorBannerProps {
  errorState: string
}

const ErrorBanner = ({ errorState }: ErrorBannerProps) => {
  return (
    <div className='with-margin'>
      {errorState !== '' ? (
        <div className='error-banner'>
          <FoxIconCaution />
          <Typography variant='subtitle1' component='div' align='left'>
            {errorState}
          </Typography>
        </div>
      ) : null}
    </div>
  )
}

export const SupportPage = ({ hwid, appFlavor, setSuccessState }: SupportFormContainerProps) => {
  const { AnalyticsEvent } = useAnalytics()

  useEffect(() => {
    AnalyticsEvent('Page visit', hwid)
  }, [AnalyticsEvent, hwid])

  const { urlSearchParams } = useUrlSearchParams()
  const [errorState, setErrorState] = useState('')

  const hwidValidator = Validator.String.isHwid(hwid)
  if (errorState === '' && !hwidValidator.valid && isValue(hwidValidator.error)) {
    setErrorState(hwidValidator.error)
  }

  const { data: device } = useQueryGetSetupInfo(hwid, setErrorState, hwidValidator.valid)

  const userEmail = urlSearchParams.get(URLQueryKeys.USER_EMAIL) ?? undefined
  const userId = urlSearchParams.get(URLQueryKeys.USER_ID) ?? undefined
  const deviceName = urlSearchParams.get(URLQueryKeys.DEVICE_NAME) ?? device?.brandingName
  const category = getEnumKeyByValue<Category>(Category, urlSearchParams.get(URLQueryKeys.CATEGORY) ?? undefined)
  const description = urlSearchParams.get(URLQueryKeys.DESCRIPTION) ?? undefined

  const supportFormFromLocalStorage = useMemo(() => LocalStorage.readSupportForm(hwid), [hwid])

  const [supportFormState, dispatchUpdateSupportFormStateAction] = useReducer<
    Reducer<SupportFormState, SupportFormStateAction>
  >(
    supportFormStateReducer,
    (() => {
      if (isValue(supportFormFromLocalStorage)) {
        return SupportFormState.fromLocalStorageType(hwid, supportFormFromLocalStorage)
      }
      return new SupportFormState({
        hwid,
        appFlavor,
        userEmail,
        userId,
        category,
        description,
        refill: undefined,
        fillLevel: undefined,
        relocated: undefined,
      })
    })(),
  )

  const mutationSendSupportEmail = useMutationSendSupportEmail(() => {
    LocalStorage.clearSupportForm(hwid)
    setSuccessState()
  }, setErrorState)
  const handleOnSubmit = () => {
    setErrorState('')

    if (!supportFormState.validateWithoutUpdatingValidationResult()) {
      dispatchUpdateSupportFormStateAction({ type: SupportFormStateActionType.VALIDATE_ALL })
      return
    }

    AnalyticsEvent('Submit request', hwid)
    mutationSendSupportEmail.mutate(supportFormState.toDto())
  }

  return (
    <div className='App'>
      <SupportHeader />

      <ErrorBanner errorState={errorState} />

      <SupportSelectedDevice appFlavor={appFlavor} deviceName={deviceName} hwid={supportFormState.hwid} />

      <SupportPartnerInfo partner={device?.partner} />

      <SupportForm
        supportFormState={supportFormState}
        dispatchUpdateSupportFormStateAction={dispatchUpdateSupportFormStateAction}
      />

      <div className='with-margin'>
        <Button
          variant='contained'
          onClick={handleOnSubmit}
          disabled={supportFormState.isInvalid() || !hwidValidator.valid}
          fullWidth
        >
          <span>{T('submit')}</span>
        </Button>
      </div>
    </div>
  )
}
