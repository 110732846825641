export enum Category {
  WRONG_FILL_LEVEL_MEASURED = 'wrongFillLevelMeasured',
  DEVICE_IS_OFFLINE = 'deviceIsOffline',
  TANK_SETUP = 'tankSetup',
  CONNECTIVITY_OF_THE_DEVICE = 'connectivityOfTheDevice',
  DATA_LOSS = 'dataLoss',
  DEVICE_INSTALLATION_ON_THE_TANK = 'deviceInstallationOnTheTank',
  OTHER_ISSUES = 'otherIssues',
}

export const categoryTranslationMap: Record<Category, string> = {
  [Category.WRONG_FILL_LEVEL_MEASURED]: 'category_WRONG_FILL_LEVEL_MEASURED',
  [Category.DEVICE_IS_OFFLINE]: 'category_DEVICE_IS_OFFLINE',
  [Category.TANK_SETUP]: 'category_TANK_SETUP',
  [Category.CONNECTIVITY_OF_THE_DEVICE]: 'category_CONNECTIVITY_OF_THE_DEVICE',
  [Category.DATA_LOSS]: 'category_DATA_LOSS',
  [Category.DEVICE_INSTALLATION_ON_THE_TANK]: 'category_DEVICE_INSTALLATION_ON_THE_TANK',
  [Category.OTHER_ISSUES]: 'category_OTHER_ISSUES',
}

export enum URLQueryKeys {
  LANG = 'lang',
  USER_EMAIL = 'userEmail',
  USER_ID = 'userId',
  DEVICE_NAME = 'deviceName',
  CATEGORY = 'category',
  DESCRIPTION = 'description',
}
