import { Grid, Typography } from '@mui/material'
import { FoxIconOilFoxHead, FoxIconPelletTracker } from 'shared/src/components/font/FoxIcon'
import { AppFlavor } from 'shared/src/model/common'
import { FoxColor } from 'shared/src/theme/foxColor'
import { T } from 'shared/src/util/translation'
import { isValue } from 'shared/src/util/typeGuard'

interface Props {
  appFlavor: AppFlavor
  deviceName: string | undefined
  hwid: string
}

export const SupportSelectedDevice = (props: Props) => {
  const { appFlavor, deviceName, hwid } = props
  const AppFlavorIcon = appFlavor === AppFlavor.FOXMOBILE ? FoxIconOilFoxHead : FoxIconPelletTracker
  return (
    <Grid container justifyContent='flex-start' alignContent='start' marginX='1em'>
      <Grid item>
        <Typography variant='body2' component='div' marginBottom='1em' color={FoxColor.grey600}>
          {T('selected_device')}
        </Typography>
      </Grid>
      <Grid item container alignItems='center' marginBottom='1em' wrap='nowrap'>
        <Grid item>
          <AppFlavorIcon className='list-icon' />
        </Grid>
        <Grid item container direction='column'>
          {isValue(deviceName) ? (
            <Grid item>
              <Typography variant='h4' component='div'>
                {deviceName}
              </Typography>
            </Grid>
          ) : null}
          <Grid item>
            <Typography variant='body2' component='div' color={FoxColor.grey600}>
              {hwid}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
