import { v4 as uuid } from 'uuid'
import { LocalStorageType } from 'shared/src/service/localStorage/localStorageModel'
import { isValueAndNotEmptyString } from 'shared/src/util/typeGuard'

export class BaseLocalStorage {
  static readString(key: LocalStorageType): string | undefined {
    const value = localStorage.getItem(key)
    if (value === null) {
      return undefined
    }
    return value
  }

  static writeString(key: LocalStorageType, value: string): void {
    localStorage.setItem(key, value)
  }

  static readLocale(): string | undefined {
    return this.readString(LocalStorageType.LOCALE)
  }

  static getGaClientId(): string {
    const gaClientId = this.readString(LocalStorageType.GA_CLIENT_ID)
    if (isValueAndNotEmptyString(gaClientId)) {
      return gaClientId
    }
    const newGaClientId = uuid()
    this.writeString(LocalStorageType.GA_CLIENT_ID, newGaClientId)
    return newGaClientId
  }

  static getAnalyticsClientId(): string {
    const analyticsClientId = this.readString(LocalStorageType.ANALYTICS_CLIENT_ID)
    if (isValueAndNotEmptyString(analyticsClientId)) {
      return analyticsClientId
    }
    const newAnalyticsClientId = uuid()
    this.writeString(LocalStorageType.ANALYTICS_CLIENT_ID, newAnalyticsClientId)
    return newAnalyticsClientId
  }
}
