// eslint-disable-next-line max-classes-per-file

import { isValue } from 'shared/src/util/typeGuard'

export const enumValueExists = <T extends object>(keyString: string, enumType: T): boolean => {
  return Object.values(enumType).includes(keyString)
}

export const getEnumKeyByValue = <T>(t: any, keyString?: string): T | undefined => {
  if (!isValue(keyString)) {
    return undefined
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const item = Object.keys(t).find((it) => t[it] === keyString)
  if (isValue(item)) {
    return t[item]
  }
  return undefined
}
