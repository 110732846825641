/* eslint-disable react/jsx-props-no-spreading */
import { Button, Grid, IconButton, Typography } from '@mui/material'
import { useState } from 'react'
import type { ImageListType } from 'react-images-uploading'
import ImageUploading from 'react-images-uploading'
import { sprintf } from 'sprintf-js'
import { FoxIconAdd, FoxIconCamera, FoxIconMinusDashHorizontalLine } from 'shared/src/components/font/FoxIcon'
import type { StrictImageType } from 'shared/src/components/imageUpload/types'
import { FoxColor } from 'shared/src/theme/foxColor'
import { resizeImageIfTooBig } from 'shared/src/util/blobUtil'
import { T } from 'shared/src/util/translation'
import { isValue } from 'shared/src/util/typeGuard'
import 'shared/src/components/imageUpload/ImageUpload.scss'

interface Props {
  images: StrictImageType[]
  uploadActionLabel?: string
  buttonLabel?: string
  maxImages?: number
  updateFormState: (images: StrictImageType[]) => void
  maxFileSizeByte: number
}

const draggingStyle = {
  color: FoxColor.blue800,
  backgroundColor: FoxColor.blue150,
  borderColor: FoxColor.blue800,
}

export const ImageUpload = ({
  images,
  uploadActionLabel,
  buttonLabel = 'take_a_picture_or_upload_a_screenshot',
  maxImages = 6,
  updateFormState,
  maxFileSizeByte,
}: Props) => {
  const [errorUploadImageMessage, setErrorUploadImageMessage] = useState('')

  const handleOnChangeImages = (imageList: ImageListType) => {
    setErrorUploadImageMessage('')
    const tempImages: Promise<StrictImageType>[] = []
    for (const image of imageList) {
      const { file, dataURL } = image
      if (isValue(file) && isValue(dataURL) && dataURL.includes('image/')) {
        tempImages.push(resizeImageIfTooBig({ dataURL, file }, maxFileSizeByte))
      }
    }
    Promise.all(tempImages).then(updateFormState)
  }

  return (
    <ImageUploading
      multiple
      value={images}
      onChange={handleOnChangeImages}
      allowNonImageType
      maxNumber={maxImages}
      onError={(errors) => {
        if (isValue(errors)) {
          if (isValue(errors.maxNumber) && errors.maxNumber) {
            setErrorUploadImageMessage(sprintf(T('error_too_many_images'), maxImages))
          } else if (isValue(errors.acceptType) && errors.acceptType) {
            setErrorUploadImageMessage(T('error_image_upload_types'))
          }
        }
      }}
    >
      {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
        <div className='upload__image-wrapper'>
          {uploadActionLabel !== '' ? (
            <div className='upload__image-action-label'>
              <Typography variant='body1' component='div' align='left'>
                {uploadActionLabel}
              </Typography>
            </div>
          ) : null}
          {imageList.length === 0 ? (
            <Button
              startIcon={<FoxIconCamera />}
              className='upload__image-button'
              variant='outlined'
              fullWidth
              onClick={onImageUpload}
              style={isDragging ? draggingStyle : undefined}
              {...dragProps}
            >
              <span>{T(buttonLabel)}</span>
            </Button>
          ) : (
            <Grid container direction='row' justifyContent='flex-start'>
              {imageList.map((image, index) => (
                <Grid key={image.dataURL} item container justifyContent='flex-start' width='fit-content'>
                  <Grid item>
                    <div key={image.file?.name} className='image-item'>
                      <img src={image.dataURL} alt='' />
                    </div>
                  </Grid>
                  <Grid item>
                    <IconButton className='image-item__btn-wrapper' onClick={() => onImageRemove(index)}>
                      <FoxIconMinusDashHorizontalLine />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              {imageList.length < 6 ? (
                <Grid item>
                  <IconButton
                    className='image-add__btn-wrapper'
                    onClick={onImageUpload}
                    style={isDragging ? draggingStyle : undefined}
                    {...dragProps}
                  >
                    <FoxIconAdd />
                  </IconButton>
                </Grid>
              ) : null}
            </Grid>
          )}
          {errorUploadImageMessage !== '' ? (
            <div className='upload__image-error'>
              <Typography variant='body1' component='div' align='left'>
                {errorUploadImageMessage}
              </Typography>
            </div>
          ) : null}
        </div>
      )}
    </ImageUploading>
  )
}
