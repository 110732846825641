/* eslint-disable react/jsx-props-no-spreading */
import type { FC, ReactNode } from 'react'
import { composeClassName } from 'shared/src/util/stringUtil'
import 'shared/src/components/font/FoxIcon.scss'

export interface FoxIconProps {
  className?: string
  cypressId?: string
  hasAction?: boolean
}

const createFoxIcon =
  (iconCode: ReactNode): FC<FoxIconProps> =>
  ({ className, hasAction, cypressId, ...rest }) => {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <span
        data-cy={cypressId}
        translate='no'
        className={composeClassName('FoxIcon', className, hasAction ?? false ? 'hasAction' : undefined)}
        {...rest}
      >
        {iconCode}
      </span>
    )
  }

export const FoxIconArrowUp = createFoxIcon('\uE000')
export const FoxIconArrowTipDown = createFoxIcon('\uE019')
export const FoxIconOilFoxHead = createFoxIcon('\uE080')
export const FoxIconUser = createFoxIcon('\uE025')
export const FoxIconCaution = createFoxIcon('\uE01E')
export const FoxIconPelletTracker = createFoxIcon('\uE08B')
export const FoxIconEnvelope = createFoxIcon('\uE091')
export const FoxIconCheckCircle = createFoxIcon('\uE066')
export const FoxIconAdd = createFoxIcon('\uE01B')
export const FoxIconPhone = createFoxIcon('\uE045')
export const FoxIconMinusDashHorizontalLine = createFoxIcon('\uE02D')
export const FoxIconCamera = createFoxIcon('\uE110')
export const FoxIconExclamationCircle = createFoxIcon('\uE068')
export const FoxIconInfo = createFoxIcon('\uE01F')
