import { produce } from 'immer'
import type { StrictImageType } from 'shared/src/components/imageUpload/types'
import type { Category } from 'support_form/src/model/common'
import type { SupportFormState } from 'support_form/src/pages/support/model/SupportFormState'

export enum SupportFormStateActionType {
  SET_USER_EMAIL = 'SET_USER_EMAIL',
  SET_CATEGORY = 'SET_CATEGORY',
  SET_DESCRIPTION = 'SET_DESCRIPTION',
  SET_REFILL = 'SET_REFILL',
  SET_FILL_LEVEL = 'SET_FILL_LEVEL',
  SET_RELOCATED = 'SET_RELOCATED',
  SET_IMAGES = 'SET_IMAGES',
  VALIDATE_USER_EMAIL = 'VALIDATE_USER_EMAIL',
  VALIDATE_CATEGORY = 'VALIDATE_CATEGORY',
  VALIDATE_DESCRIPTION = 'VALIDATE_DESCRIPTION',
  VALIDATE_REFILL = 'VALIDATE_REFILL',
  VALIDATE_FILL_LEVEL = 'VALIDATE_FILL_LEVEL',
  VALIDATE_RELOCATED = 'VALIDATE_RELOCATED',
  VALIDATE_ALL = 'VALIDATE_ALL',
}

interface SetUserEmail {
  type: SupportFormStateActionType.SET_USER_EMAIL
  payload: { userEmail: string }
}
interface SetCategory {
  type: SupportFormStateActionType.SET_CATEGORY
  payload: { category: Category }
}
interface SetDescription {
  type: SupportFormStateActionType.SET_DESCRIPTION
  payload: { description: string }
}
interface SetRefill {
  type: SupportFormStateActionType.SET_REFILL
  payload: { refill: boolean }
}
interface SetFillLevel {
  type: SupportFormStateActionType.SET_FILL_LEVEL
  payload: { fillLevel: string }
}
interface SetRelocated {
  type: SupportFormStateActionType.SET_RELOCATED
  payload: { relocated: boolean }
}
interface SetImages {
  type: SupportFormStateActionType.SET_IMAGES
  payload: { images: StrictImageType[] }
}
interface ValidateUserEmail {
  type: SupportFormStateActionType.VALIDATE_USER_EMAIL
}
interface ValidateCategory {
  type: SupportFormStateActionType.VALIDATE_CATEGORY
}
interface ValidateDescription {
  type: SupportFormStateActionType.VALIDATE_DESCRIPTION
}
interface ValidateRefill {
  type: SupportFormStateActionType.VALIDATE_REFILL
}
interface ValidateFillLevel {
  type: SupportFormStateActionType.VALIDATE_FILL_LEVEL
}
interface ValidateRelocated {
  type: SupportFormStateActionType.VALIDATE_RELOCATED
}
interface ValidateAll {
  type: SupportFormStateActionType.VALIDATE_ALL
}

export type SupportFormStateAction =
  | SetUserEmail
  | SetCategory
  | SetDescription
  | SetRefill
  | SetFillLevel
  | SetRelocated
  | SetImages
  | ValidateUserEmail
  | ValidateCategory
  | ValidateDescription
  | ValidateRefill
  | ValidateFillLevel
  | ValidateRelocated
  | ValidateAll

/* eslint-disable no-param-reassign */
export const supportFormStateReducer = produce((draft: SupportFormState, action: SupportFormStateAction) => {
  switch (action.type) {
    case SupportFormStateActionType.SET_USER_EMAIL:
      draft.userEmail.value = action.payload.userEmail
      break
    case SupportFormStateActionType.SET_CATEGORY:
      draft.category.value = action.payload.category

      draft.description.resetValidationError()
      draft.refill.resetValidationError()
      draft.fillLevel.resetValidationError()
      draft.relocated.resetValidationError()
      break
    case SupportFormStateActionType.SET_DESCRIPTION:
      draft.description.value = action.payload.description
      break
    case SupportFormStateActionType.SET_REFILL:
      draft.refill.value = action.payload.refill
      break
    case SupportFormStateActionType.SET_FILL_LEVEL:
      draft.fillLevel.value = action.payload.fillLevel
      break
    case SupportFormStateActionType.SET_RELOCATED:
      draft.relocated.value = action.payload.relocated
      break
    case SupportFormStateActionType.SET_IMAGES:
      draft.images = action.payload.images
      break
    case SupportFormStateActionType.VALIDATE_USER_EMAIL:
      draft.userEmail.validate()
      break
    case SupportFormStateActionType.VALIDATE_CATEGORY:
      draft.category.validate()
      break
    case SupportFormStateActionType.VALIDATE_DESCRIPTION:
      draft.description.validate()
      break
    case SupportFormStateActionType.VALIDATE_REFILL:
      draft.refill.validate()
      break
    case SupportFormStateActionType.VALIDATE_FILL_LEVEL:
      draft.fillLevel.validate()
      break
    case SupportFormStateActionType.VALIDATE_RELOCATED:
      draft.relocated.validate()
      break
    case SupportFormStateActionType.VALIDATE_ALL:
      draft.userEmail.validate()
      draft.category.validate()
      draft.description.validate()
      draft.refill.validate()
      draft.fillLevel.validate()
      draft.relocated.validate()
      break
    default:
      break
  }
})
