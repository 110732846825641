import { useMutation } from '@tanstack/react-query'
import { T } from 'shared/src/util/translation'
import type { DtoSupportEmailRequest } from 'support_form/src/pages/support/queries/supportFormDtos'
import { httpClient } from 'support_form/src/service/http/HttpClient'

export const useMutationSendSupportEmail = (onSuccess: () => void, setErrorState: (value: string) => void) => {
  return useMutation({
    mutationFn: async (request: DtoSupportEmailRequest) => {
      const { data } = await httpClient.sendSupportEmail(request)
      return data
    },
    onSuccess,
    onError: (error: Error) => {
      switch (error.message) {
        case 'Request failed with status code 400':
          setErrorState(T('httpError_400'))
          break
        case 'Request failed with status code 404':
          setErrorState(T('httpError_404'))
          break
        case 'Request failed with status code 429':
          setErrorState(T('httpError_429'))
          break
        case 'Request failed with status code 412':
          setErrorState(T('httpError_412'))
          break
        case 'Request failed with status code 500':
          setErrorState(T('httpError_500'))
          break
        default:
      }
    },
  })
}
