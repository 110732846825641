import { createTheme } from '@mui/material'
import { FoxColor } from 'shared/src/theme/foxColor'

export const muiTheme = createTheme({
  palette: {
    common: {
      black: FoxColor.baseBlack,
      white: FoxColor.baseWhite,
    },
    primary: {
      dark: FoxColor.blue800,
      main: FoxColor.blue600,
      light: FoxColor.blue400,
      contrastText: FoxColor.baseWhite,
    },
    secondary: {
      dark: FoxColor.grey600,
      main: FoxColor.grey400,
      light: FoxColor.grey200,
      contrastText: FoxColor.baseWhite,
    },
    error: {
      dark: FoxColor.red800,
      main: FoxColor.red600,
      light: FoxColor.red400,
      contrastText: FoxColor.baseWhite,
    },
    warning: {
      dark: FoxColor.orangy800,
      main: FoxColor.orangy600,
      light: FoxColor.orangy400,
      contrastText: FoxColor.baseWhite,
    },
    info: {
      main: FoxColor.blue600,
      contrastText: FoxColor.baseWhite,
    },
    success: {
      dark: FoxColor.green800,
      main: FoxColor.green600,
      light: FoxColor.green200,
      contrastText: FoxColor.baseWhite,
    },
    grey: {
      100: FoxColor.grey100,
      200: FoxColor.grey200,
      400: FoxColor.grey400,
      600: FoxColor.grey600,
      800: FoxColor.grey800,
    },
    contrastThreshold: 2,
    tonalOffset: 0.05,
    text: {
      primary: FoxColor.grey800,
      secondary: FoxColor.grey600,
      disabled: FoxColor.grey600,
    },
    background: {
      paper: FoxColor.baseWhite,
      default: FoxColor.blue100,
    },
  },
  typography: {
    fontFamily: ['SuisseIntl', 'Arial', 'sans-serif'].join(','),
    fontSize: 14,
    h1: {
      fontSize: '3rem',
      lineHeight: 1.28,
      fontWeight: 600,
    },
    h2: {
      fontSize: '2rem',
      lineHeight: 1.25,
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.5rem',
      lineHeight: 1.33,
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.33rem',
      lineHeight: 1.25,
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.17rem',
      lineHeight: 1.43,
      fontWeight: 600,
    },
    h6: {
      fontSize: '0.83rem',
      lineHeight: 1.07,
      fontWeight: 600,
      letterSpacing: 1.25,
    },
    subtitle1: {
      fontSize: '1.5rem',
      lineHeight: 1.33,
      fontWeight: 400,
    },
    body1: {
      fontSize: '1.33rem',
      lineHeight: 1.25,
    },
    body2: {
      fontSize: '1.17rem',
      lineHeight: 1.43,
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiFormLabel: { styleOverrides: { root: { fontSize: '14px', paddingLeft: '14px', paddingBottom: '10px' } } },
    MuiOutlinedInput: {
      styleOverrides: {
        input: { padding: '14px' },
        root: { padding: 0 },
        notchedOutline: {
          borderColor: FoxColor.grey200,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: { backgroundColor: FoxColor.blue100 },
        input: { backgroundColor: FoxColor.blue100 },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: { content: { margin: '20px 0' }, expandIconWrapper: { color: FoxColor.baseBlack } },
    },
    MuiAccordionDetails: { styleOverrides: { root: { borderTop: '1px solid rgba(0, 0, 0, 0.12)' } } },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          '&:before': {
            height: 0,
          },
        },
      },
    },
    MuiFormHelperText: { styleOverrides: { root: { fontSize: '1rem' } } },
    MuiButton: {
      styleOverrides: {
        outlined: {
          color: FoxColor.blue600,
          borderColor: FoxColor.grey200,
          backgroundColor: FoxColor.blue100,
        },
        root: { textTransform: 'none', boxShadow: 'none', fontSize: '1.33rem' },
      },
    },
  },
})
