import type { AxiosInstance } from 'axios'
import axios from 'axios'
import type { CustomAxiosRequestConfig } from 'shared/src/util/axios'
import { API_VERSION, getBaseApiUrl } from 'shared/src/util/environment'

export class BaseHttpClient {
  protected readonly client: AxiosInstance

  constructor() {
    this.client = axios.create({
      ...axios.defaults,
      headers: { ...axios.defaults.headers.common, 'Api-Version': API_VERSION },
      baseURL: getBaseApiUrl(),
    } as CustomAxiosRequestConfig)
  }
}
