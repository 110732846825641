import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
import { FoxIconArrowTipDown, FoxIconEnvelope, FoxIconPhone, FoxIconUser } from 'shared/src/components/font/FoxIcon'
import { useAnalytics } from 'shared/src/context/AnalyticsContext'
import { FoxColor } from 'shared/src/theme/foxColor'
import { T } from 'shared/src/util/translation'
import type { DtoAppPartnerV60 } from 'support_form/src/pages/support/queries/supportFormDtos'

interface Props {
  partner: DtoAppPartnerV60 | undefined
}

export const SupportPartnerInfo = (props: Props) => {
  const { AnalyticsEvent } = useAnalytics()
  const { partner } = props
  return (
    <Accordion disableGutters onChange={(_, expanded) => AnalyticsEvent('Partner info expand', String(expanded))}>
      <AccordionSummary expandIcon={<FoxIconArrowTipDown />} aria-controls='panel1a-content' id='panel1a-header'>
        <Grid container wrap='nowrap' alignItems='center'>
          <Grid color={FoxColor.blue600} item>
            <FoxIconPhone className='list-icon' />
          </Grid>
          <Grid item>
            <Typography variant='h4' component='div'>
              {T('contact_details')}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant='body1' component='div' marginBottom='1em' color={FoxColor.grey600}>
          {T('contact_partner')}
        </Typography>
        <Grid container wrap='nowrap' alignItems='center' marginBottom='1em'>
          <Grid item>
            <FoxIconUser className='list-icon' />
          </Grid>
          <Grid item>
            <Typography variant='h4' component='div'>
              {partner?.displayName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container wrap='nowrap' alignItems='center' marginBottom='1em' color={FoxColor.blue600}>
          <Grid item>
            <FoxIconEnvelope className='list-icon' />
          </Grid>
          <Grid item>
            <Typography variant='body1' component='div'>
              <a href={`mailto:${partner?.supportEmail}`}>{partner?.supportEmail}</a>
            </Typography>
          </Grid>
        </Grid>
        <Grid container wrap='nowrap' alignItems='center' color={FoxColor.blue600} marginBottom='1em'>
          <Grid item>
            <FoxIconPhone className='list-icon' />
          </Grid>
          <Grid item>
            <Typography variant='body1' component='div'>
              <a href={`tel:${partner?.supportPhone}`}>{partner?.supportPhone}</a>
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
