export enum FoxColor {
  baseBlack = '#000000',
  baseWhite = '#FFFFFF',

  grey800 = '#202224',
  grey600 = '#777777',
  grey400 = '#AAAAAA',
  grey200 = '#D5D5D5',
  grey100 = '#E0E0E0',

  blue800 = '#004EFF',
  blue600 = '#4880FF',
  blue400 = '#B9CEFF',
  blue200 = '#DBE4FD',
  blue150 = '#F0F2FB',
  blue100 = '#F5F6FA',
  blue50 = '#FBFCFF',

  green800 = '#269723',
  green600 = '#3ACC5A',
  green400 = '#7BDD90',
  green200 = '#BCEEC5',

  red800 = '#D3003A',
  red600 = '#FF0046',
  red400 = '#FF4D73',
  red200 = '#F1BDC6',

  orangy800 = '#FF902D',
  orangy600 = '#FFBD04',
  orangy400 = '#FFDC75',
  orangy200 = '#FFF2C1',
}

export const setFoxColors = () => {
  for (const [key, value] of Object.entries(FoxColor)) {
    document.documentElement.style.setProperty(`--${key}`, `${value}`)
  }
}
