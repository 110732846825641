import type { PropsWithChildren } from 'react'
import { useContext, createContext, useMemo } from 'react'
import { WorkspaceAnalyticsEvent } from 'shared/src/util/trackingHelpers'
import { isValue } from 'shared/src/util/typeGuard'

export type AnalyticsEventType = (action: string, label?: string, value?: number, nonInteraction?: boolean) => void

type AnalyticsContextType = {
  AnalyticsEvent: AnalyticsEventType
}

const AnalyticsContext = createContext<AnalyticsContextType>({
  AnalyticsEvent: () => {
    return undefined
  },
})

type AnalyticsProps = {
  category: string
}

const AnalyticsContextProvider = (props: PropsWithChildren<AnalyticsProps>) => {
  const { category, children } = props
  const contextValue = useMemo(
    () => ({
      AnalyticsEvent: (action: string, label?: string, value?: number, nonInteraction?: boolean) =>
        WorkspaceAnalyticsEvent(category, action, label, value, nonInteraction),
    }),
    [category],
  )

  return <AnalyticsContext.Provider value={contextValue}>{children}</AnalyticsContext.Provider>
}

const useAnalytics = () => {
  const context = useContext(AnalyticsContext)

  if (!isValue(context)) {
    throw new Error('The hook useAnalytics must be used inside a AnalyticsContext')
  }

  return context
}

export { AnalyticsContext, AnalyticsContextProvider, useAnalytics }
