import { BaseLocalStorage } from 'shared/src/service/localStorage/BaseLocalStorage'
import { isValue } from 'shared/src/util/typeGuard'
import type { LSSupportForm } from 'support_form/src/localStorage/LSSupportForm'

enum LocalStorageType {
  SUPPORT_FORM_STATE = 'state',
}

const supportFormKey = (hwid: string) => `${LocalStorageType.SUPPORT_FORM_STATE}_${hwid}`

export class LocalStorage extends BaseLocalStorage {
  static readSupportForm(hwid: string): LSSupportForm | undefined {
    const value = localStorage.getItem(supportFormKey(hwid))
    if (!isValue(value)) {
      return undefined
    }
    return JSON.parse(value)
  }

  static writeSupportForm(hwid: string, sf: LSSupportForm) {
    localStorage.setItem(supportFormKey(hwid), JSON.stringify(sf))
  }

  static clearSupportForm(hwid: string) {
    localStorage.removeItem(supportFormKey(hwid))
  }
}
