import {
  FoxIconCaution,
  FoxIconCheckCircle,
  FoxIconExclamationCircle,
  FoxIconInfo,
} from 'shared/src/components/font/FoxIcon'
import { composeClassName } from 'shared/src/util/stringUtil'
import 'shared/src/components/banner/FoxBanner.scss'

export enum BannerType {
  DEFAULT = 'default',
  SUCCESS = 'success',
  WARNING = 'warning',
  ALERT = 'alert',
}

const bannerIcon = {
  [BannerType.DEFAULT]: <FoxIconInfo />,
  [BannerType.SUCCESS]: <FoxIconCheckCircle />,
  [BannerType.WARNING]: <FoxIconCaution />,
  [BannerType.ALERT]: <FoxIconExclamationCircle />,
}

type Props = {
  type?: BannerType
  content: string
}

export const FoxBanner = ({ type = BannerType.DEFAULT, content }: Props) => {
  return (
    <div className={composeClassName('FoxBanner', type)}>
      {bannerIcon[type]}
      <div className='content'>{content}</div>
    </div>
  )
}
