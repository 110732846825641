export const isValue = <T>(variable: T | null | undefined): variable is T => variable != null

export const isNotEmptyString = (value: string) => value !== ''

export const isValueAndNotEmptyString = (value: string | null | undefined): value is string => {
  return isValue(value) && isNotEmptyString(value)
}

export const isValueAndNotEmptyTrimmedString = (value: string | null | undefined): value is string => {
  return isValueAndNotEmptyString(value) && isNotEmptyString(value.trim())
}

export const isEmptyTrimmedString = (value: string): boolean => {
  return !isNotEmptyString(value.trim())
}
