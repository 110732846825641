import { immerable } from 'immer'
import type { ValidatorFunction, ValidationResult } from 'shared/src/model/validator'
import { isValue } from 'shared/src/util/typeGuard'

export class ValidField<T> {
  public [immerable] = true

  private _value: T
  private readonly validators: ValidatorFunction<T>[]
  private validationResult: ValidationResult[]

  constructor(value: T, validators: ValidatorFunction<T>[]) {
    this._value = value
    this.validators = validators
    this.validationResult = []
  }

  get value(): T {
    return this._value
  }

  set value(value: T) {
    this._value = value
    this.validateIfInvalid()
  }

  static getErrorCount(fields: ValidField<any>[]): number {
    return fields.reduce((previousValue, currentValue) => {
      if (!currentValue.isValid()) {
        return previousValue + 1
      }
      return previousValue
    }, 0)
  }

  static validate<F>(fields: ValidField<any>[], customValidator?: ValidatorFunction<F>): void {
    fields.forEach((it) => it.validate(customValidator))
  }

  static validateWithoutUpdatingValidationResult<V>(
    fields: ValidField<any>[],
    customValidator?: ValidatorFunction<V>,
  ): boolean {
    return fields.map((it) => it.validateWithoutUpdatingValidationResult(customValidator)).every(id)
  }

  public setValue(value: T, customValidator: ValidatorFunction<T>): void {
    this._value = value
    this.validateIfInvalid(customValidator)
  }

  public setAsInvalid(errorMsg: string) {
    this.validationResult = this.validationResult.concat({ valid: false, error: errorMsg })
  }

  public validate(customValidator?: ValidatorFunction<T>): void {
    const validators = isValue(customValidator) ? [customValidator, ...this.validators] : this.validators
    this.validationResult = validators.map((it) => it(this._value))
  }

  public validateIfInvalid(customValidator?: ValidatorFunction<T>): void {
    if (!this.isValid()) {
      this.validate(customValidator)
    }
  }

  public getFirstValidationError(): string | undefined {
    return this.validationResult.find((result) => !result.valid)?.error
  }

  public isValid(): boolean {
    return this.validationResult.every((validationResult) => validationResult.valid)
  }

  public validateWithoutUpdatingValidationResult(customValidator?: ValidatorFunction<T>): boolean {
    const validators = isValue(customValidator) ? [...this.validators, customValidator] : this.validators
    const validationResults = validators.map((it) => it(this._value))
    return validationResults.every((validationResult) => validationResult.valid)
  }

  public resetValidationError(): void {
    this.validationResult = []
  }
}

export const id = <T>(it: T): T => it
