import type { AxiosResponse } from 'axios'
import { BaseHttpClient } from 'shared/src/service/http/BaseHttpClient'
import type { DtoAppSetupDevice, DtoSupportEmailRequest } from 'support_form/src/pages/support/queries/supportFormDtos'

class HttpClient extends BaseHttpClient {
  getSetupInfoV60(hwid: string): Promise<AxiosResponse<DtoAppSetupDevice>> {
    return this.client.get<DtoAppSetupDevice>(`/support/device/${hwid}`)
  }

  sendSupportEmail(request: DtoSupportEmailRequest): Promise<AxiosResponse<undefined>> {
    const { hwid, body, pictures } = request
    const formData = new FormData()
    formData.append('file', new Blob([JSON.stringify(body)], { type: 'application/json' }), 'body')
    for (const picture of pictures) {
      formData.append('file', picture.bytes, picture.name)
    }
    return this.client.post(`/support/device/${hwid}`, formData, { headers: { 'content-type': 'multipart/form-data' } })
  }
}

export const httpClient = new HttpClient()
