const hwidBase = '[0-9A-Z]{8,12}'

export const Regex = {
  colorHex: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
  number: /^(0|[1-9][0-9]*)$/,
  email: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
  floatNumberEN: /^[0-9]+[.][0-9]+$/,
  floatNumberDE: /^[0-9]+[,][0-9]+$/,
  floatNumberFR: /^[0-9]+[,][0-9]+$/,
  hwid: RegExp(hwidBase),
  hwidsMultiple: RegExp(`^(${hwidBase})([\\s,;]+${hwidBase})*$`),
  hwidDemo: /^(DEMO)\S{4}$/,
  hwidNbiot: /^.(N)\S{10}$/,
  hwidOilfox2Wifi: /^(OW)\S{10}$/,
  hwidFoxRadar: /^..(R)\S{9}$/,
  hwidFoxHall: /^..(H)\S{9}$/,
  hwidPellet: /^(P)\S{11}$/,
  imageUrl: /(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif))/i,
  thousandSeparatedIntegerEN: /^-?0*([1-9][0-9]*([,][0-9]{3})*)$/,
  thousandSeparatedIntegerDE: /^-?0*([1-9][0-9]*([.][0-9]{3})*)$/,
  thousandSeparatedIntegerFR: /^-?0*([1-9][0-9]*([\s][0-9]{3})*)$/,
  thousandSeparatedFloatNumberEN: /^-?0*(([1-9][0-9]*)([,][0-9]{3})*)([.][0-9]+)?$/,
  thousandSeparatedFloatNumberDE: /^-?0*(([1-9][0-9]*)([.][0-9]{3})*)([,][0-9]+)?$/,
  thousandSeparatedFloatNumberFR: /^-?0*(([1-9][0-9]*)([\s][0-9]{3})*)([,][0-9]+)?$/,
  pelletTracker: /(\.pellet-?tracker\.)/, // also hardcoded in index.html
  foxinsights: /(\.foxinsights\.)/,
  url: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
  uuid: /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/,
}
