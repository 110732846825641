import * as Sentry from '@sentry/react'
import { simpleAnalytics } from 'shared/src/service/analytics/SimpleAnalytics'
import { BaseLocalStorage } from 'shared/src/service/localStorage/BaseLocalStorage'
import { getEnvironment } from 'shared/src/util/environment'

export const trackingInfo = {
  isSimpleAnalyticsInitialized: false,
}

const isDeployed = /.*foxinsights|foxmobile.*/.test(window.location.hostname)

export const initializeSimpleAnalytics = () => {
  if (!trackingInfo.isSimpleAnalyticsInitialized) {
    const analyticsClientId = BaseLocalStorage.getAnalyticsClientId()
    if (isDeployed) {
      simpleAnalytics.init(analyticsClientId)
      trackingInfo.isSimpleAnalyticsInitialized = true
    }
  }
}

export const initializeSentry = (dsn: string) => {
  Sentry.init({
    dsn,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay({ maskAllText: false })],
    // Performance Monitoring
    tracesSampleRate: 0.1,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: getEnvironment(),
  })
}

export const WorkspaceAnalyticsEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number,
  nonInteraction?: boolean,
) => {
  if (trackingInfo.isSimpleAnalyticsInitialized) {
    simpleAnalytics.logEvent(action, { category, label, value, nonInteraction })
  }
}

export enum ANALYTICS_CATEGORY {
  WHATS_INSIDE = 'Whats inside',
  INSTALLATION_IMAGES = 'Installation images',
}
