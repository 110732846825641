import { getEnumKeyByValue } from 'shared/src/util/typeChecks'
import { isValue } from 'shared/src/util/typeGuard'
import { CommonUrlQueryKeys } from 'shared/src/util/url'

export const API_VERSION = 141

export enum Environment {
  PROD = 'prod',
  STAGING = 'staging',
  DEVELOP = 'develop',
  LOCAL = 'local',
}

export const apiUrls: Record<Environment, string> = {
  [Environment.STAGING]: 'https://api.staging.oilfox.io',
  [Environment.PROD]: 'https://api.oilfox.io',
  [Environment.DEVELOP]: 'https://api.develop.oilfox.io',
  [Environment.LOCAL]: 'http://localhost:5000',
}

const environment =
  getEnumKeyByValue<Environment>(
    Environment,
    new URLSearchParams(window.location.search).get(CommonUrlQueryKeys.ENV) ?? undefined,
  ) ?? (process.env.REACT_APP_ENVIRONMENT as Environment)

export const getEnvironment = () => environment
export const getBaseApiUrl = (): string => {
  if (isValue(apiUrls[environment])) {
    return apiUrls[environment]
  }
  throw Error('Unsupported environment')
}

export const isRunningInProductionEnvironment = (): boolean => environment === Environment.PROD
export const isRunningInStagingEnvironment = (): boolean => environment === Environment.STAGING
export const isRunningInDevelopEnvironment = (): boolean => environment === Environment.DEVELOP

export const Secrets = {
  sentry: {
    installationImagesDSN:
      'https://02d201dc5b44ed4b43e7e5293ffecd51@o4507095007559680.ingest.de.sentry.io/4507095110123600',
    supportFormDSN: 'https://dfa18d6eeaf1a610fe89f7fbcd165ace@o4507095007559680.ingest.de.sentry.io/4507095111106640',
    whatsInsideDSN: 'https://340435f7762b5714a39f10e5266d8f92@o4507095007559680.ingest.de.sentry.io/4507095111958608',
  },
}
