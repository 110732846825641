/* eslint-disable react/jsx-props-no-spreading */
import type { TypographyProps } from '@mui/material'
import { FormLabel, Typography } from '@mui/material'
import type { ForwardedRef, PropsWithChildren } from 'react'
import { forwardRef, useLayoutEffect, useRef } from 'react'
import type { ChildrenProps } from 'shared/src/model/common'
import { composeClassName } from 'shared/src/util/stringUtil'
import { isValue } from 'shared/src/util/typeGuard'

export type FoxTextProps = Omit<TypographyProps, 'variant'> & { bold?: boolean }
type FoxTextPropsWithRef = FoxTextProps & {
  ref?: ForwardedRef<HTMLSpanElement>
}

export const FoxDynamicFontSizeText = ({
  children,
  minFontSize,
  ...rest
}: PropsWithChildren<TypographyProps & { minFontSize: number }>) => {
  const ref = useRef<HTMLSpanElement>(null)

  useLayoutEffect(() => {
    if (isValue(ref.current)) {
      changeFontSizeToFitContainerHeight(ref.current, minFontSize)
    }
  }, [minFontSize, children])

  return (
    <Typography ref={ref} {...rest}>
      {children}
    </Typography>
  )
}

export const FoxTextH1 = ({ children, ...rest }: PropsWithChildren<FoxTextProps>) => (
  <Typography tabIndex={0} variant='h1' {...rest}>
    {children}
  </Typography>
)

export const FoxTextH2 = ({ children, ...rest }: PropsWithChildren<FoxTextProps>) => (
  <Typography tabIndex={0} variant='h2' {...rest}>
    {children}
  </Typography>
)

export const FoxTextH3 = ({ children, ...rest }: PropsWithChildren<FoxTextProps>) => (
  <Typography tabIndex={0} variant='h3' {...rest}>
    {children}
  </Typography>
)

export const FoxTextH4 = ({ children, ...rest }: PropsWithChildren<FoxTextProps>) => (
  <Typography tabIndex={0} variant='h4' {...rest}>
    {children}
  </Typography>
)

export const FoxTextH5 = ({ children, ...rest }: PropsWithChildren<FoxTextProps>) => (
  <Typography tabIndex={0} variant='h5' {...rest}>
    {children}
  </Typography>
)

export const FoxTextSubtitle1 = ({ children, ...rest }: PropsWithChildren<FoxTextProps>) => (
  <Typography tabIndex={0} variant='subtitle1' {...rest}>
    {children}
  </Typography>
)

export const FoxTextBody1 = forwardRef<HTMLSpanElement, PropsWithChildren<FoxTextPropsWithRef>>(
  ({ children, className, bold = false, ...rest }, ref) => (
    <Typography
      {...rest}
      ref={ref}
      tabIndex={0}
      variant='body1'
      className={composeClassName(className)}
      sx={{ fontWeight: bold ? '600' : '' }}
    >
      {children}
    </Typography>
  ),
)

export const FoxTextBody2 = forwardRef<HTMLSpanElement, PropsWithChildren<FoxTextPropsWithRef>>(
  ({ children, className, bold = false, ...rest }, ref) => (
    <Typography
      {...rest}
      ref={ref}
      tabIndex={0}
      variant='body2'
      className={composeClassName(className)}
      sx={{ fontWeight: bold ? '600' : '' }}
    >
      {children}
    </Typography>
  ),
)

export const FoxCustomFormLabel = ({ children }: ChildrenProps) => (
  <FormLabel style={{ position: 'relative', bottom: 5, fontSize: 10, marginLeft: 10 }}>{children}</FormLabel>
)

const changeFontSizeToFitContainerHeight = (element: HTMLSpanElement, minFontSize: number) => {
  const elementFontSize = parseFloat(window.getComputedStyle(element).fontSize)

  const elementHeight = element.clientHeight
  const elementContainerHeight = element.parentElement?.clientHeight

  if (isValue(elementContainerHeight) && elementHeight >= elementContainerHeight) {
    if (elementFontSize > minFontSize) {
      /* eslint-disable no-param-reassign */
      element.style.fontSize = `${elementFontSize - 1}px `
      changeFontSizeToFitContainerHeight(element, minFontSize)
    } else {
      element.style.whiteSpace = 'nowrap'
      element.style.overflow = 'hidden'
      element.style.textOverflow = 'ellipsis'
      element.title = element.textContent ?? ''
      /* eslint-enable no-param-reassign */
    }
  }
}
