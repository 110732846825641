import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { T } from 'shared/src/util/translation'
import { httpClient } from 'support_form/src/service/http/HttpClient'

const GET_SETUP_INFO_QUERY_KEY = 'useQueryGetSetupInfo'

export const useQueryGetSetupInfo = (hwid: string, setErrorState: (value: string) => void, enabled: boolean) => {
  const query = useQuery({
    queryKey: [GET_SETUP_INFO_QUERY_KEY],
    queryFn: async () => {
      const { data } = await httpClient.getSetupInfoV60(hwid)
      return data
    },
    enabled,
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      if (error.message === 'Request failed with status code 404') {
        return false
      }
      return failureCount < 3
    },
  })

  useEffect(() => {
    if (query.error) {
      if (query.error.message === 'Request failed with status code 404') {
        setErrorState(T('httpError_404'))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.error])

  return query
}
