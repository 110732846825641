import type { StrictImageType } from 'shared/src/components/imageUpload/types'

const reduceQuality = (width: number, height: number, imageElement: HTMLImageElement, image: StrictImageType) => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  canvas.width = width
  canvas.height = height
  ctx?.drawImage(imageElement, 0, 0, width, height)
  const onlyLowerQualityDataURI = canvas.toDataURL(image.file.type, 0.6)
  return {
    dataURL: onlyLowerQualityDataURI,
    file: dataURLtoFile(onlyLowerQualityDataURI, image.file),
  }
}

const resizeResolution = (
  onlyLowerQuality: { dataURL: string; file: File },
  width: number,
  height: number,
  img: HTMLImageElement,
  image: StrictImageType,
  maxFileSizeByte: number,
) => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const resizeFactor = maxFileSizeByte / onlyLowerQuality.file.size
  const reducedWidth = width * resizeFactor
  const reducedHeight = height * resizeFactor

  canvas.width = reducedWidth
  canvas.height = reducedHeight

  ctx?.drawImage(img, 0, 0, reducedWidth, reducedHeight)

  const lowerQualityAndReducedResolutionDataURI = canvas.toDataURL(image.file.type)
  return {
    dataURL: lowerQualityAndReducedResolutionDataURI,
    file: dataURLtoFile(lowerQualityAndReducedResolutionDataURI, image.file),
  }
}

export const resizeImageIfTooBig = (image: StrictImageType, maxFileSizeByte: number): Promise<StrictImageType> => {
  return new Promise<StrictImageType>((resolve) => {
    if (image.file.size <= maxFileSizeByte) {
      resolve(image)
      return
    }

    const img = document.createElement('img')

    img.onload = () => {
      const { width, height } = img

      // first: Try with quality 0.6
      const onlyLowerQuality = reduceQuality(width, height, img, image)

      if (onlyLowerQuality.file.size <= maxFileSizeByte) {
        resolve(onlyLowerQuality)
        return
      }

      // next: Change additionally image resolution
      resolve(resizeResolution(onlyLowerQuality, width, height, img, image, maxFileSizeByte))
    }

    img.src = image.dataURL
  })
}

export const dataURLtoFile = (dataURI: string, file: File) => {
  const byteString = window.atob(dataURI.split(',')[1])
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const byteArray = new Uint8Array(arrayBuffer)
  for (let i = 0; i < byteString.length; i += 1) {
    byteArray[i] = byteString.charCodeAt(i)
  }
  return new File([byteArray], file.name, { type: file.type })
}
