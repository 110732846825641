/* eslint-disable import/no-duplicates */
import languages from '@cospired/i18n-iso-languages'
import type { Locale as DateLocale } from 'date-fns'
import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en-GB'
import frLocale from 'date-fns/locale/fr'
import { AppFlavor } from 'shared/src/model/common'
import { BaseLocalStorage } from 'shared/src/service/localStorage/BaseLocalStorage'
import { CountryCode } from 'shared/src/util/CountryCode'
import { enumValueExists, getEnumKeyByValue } from 'shared/src/util/typeChecks'
import { isValue } from 'shared/src/util/typeGuard'
import { CommonUrlQueryKeys } from 'shared/src/util/url'

export enum LanguageCode {
  GERMAN = 'de',
  ENGLISH = 'en',
  FRENCH = 'fr',
}

export enum Locale {
  GERMAN = 'de-DE',
  ENGLISH = 'en-GB',
  FRENCH = 'fr-FR',
}

export const languageCodeTranslationMap = (): Record<LanguageCode, string | undefined> => ({
  [LanguageCode.GERMAN]: new Language(LanguageCode.GERMAN).i18nName,
  [LanguageCode.ENGLISH]: new Language(LanguageCode.ENGLISH).i18nName,
  [LanguageCode.FRENCH]: new Language(LanguageCode.FRENCH).i18nName,
})

export const defaultLangMap: Record<LanguageCode, CountryCode[]> = {
  de: [CountryCode.DE, CountryCode.AT, CountryCode.CH, CountryCode.LI, CountryCode.LU],
  en: [
    CountryCode.GB,
    CountryCode.BE,
    CountryCode.CZ,
    CountryCode.DK,
    CountryCode.HU,
    CountryCode.HR,
    CountryCode.IE,
    CountryCode.NL,
    CountryCode.PL,
    CountryCode.RO,
    CountryCode.SK,
    CountryCode.SI,
    CountryCode.BG,
    CountryCode.EE,
    CountryCode.FI,
    CountryCode.GR,
    CountryCode.IT,
    CountryCode.RS,
    CountryCode.LT,
    CountryCode.NO,
    CountryCode.PT,
    CountryCode.ES,
    CountryCode.SE,
  ],
  fr: [CountryCode.FR],
}
export const langCodeToLocaleMap: Record<LanguageCode, Locale> = {
  de: Locale.GERMAN,
  en: Locale.ENGLISH,
  fr: Locale.FRENCH,
}
export const langCodeToDateLocaleMap: Record<LanguageCode, DateLocale> = {
  de: deLocale,
  en: enLocale,
  fr: frLocale,
}
export const localeToLangCodeMap: Record<Locale, LanguageCode> = {
  [Locale.GERMAN]: LanguageCode.GERMAN,
  [Locale.ENGLISH]: LanguageCode.ENGLISH,
  [Locale.FRENCH]: LanguageCode.FRENCH,
}

type LocaleSupport = {
  locale: Locale
  supportFor: string[]
}

const localeSupport: LocaleSupport[] = [
  { locale: Locale.FRENCH, supportFor: [LanguageCode.FRENCH] },
  { locale: Locale.GERMAN, supportFor: [LanguageCode.GERMAN] },
]

export const getLanguageConfig = (): { locale: Locale; langCode: LanguageCode } => {
  let locale = getLocale()
  let langCode = locale.split('-')[0] as LanguageCode

  if (!isValue(locale) || !enumValueExists(langCode, LanguageCode)) {
    locale = Locale.ENGLISH
    langCode = LanguageCode.ENGLISH
  }

  document.documentElement.lang = langCode

  return {
    locale,
    langCode,
  }
}

const getLocale = (): Locale => {
  const langFromUrl = new URLSearchParams(window.location.search).get(CommonUrlQueryKeys.LANG)
  if (isValue(langFromUrl) && enumValueExists(langFromUrl, LanguageCode)) {
    return langCodeToLocaleMap[langFromUrl as LanguageCode]
  }

  const localeFromLocalStorage = BaseLocalStorage.readLocale()
  if (isValue(localeFromLocalStorage) && enumValueExists(localeFromLocalStorage, Locale)) {
    return localeFromLocalStorage as Locale
  }

  return localeSupport.find((it) => it.supportFor.includes(navigator.language.split('-')[0]))?.locale ?? Locale.ENGLISH
}

export const getDeviceBrandName = (): string => {
  const { location } = window
  const appFlavor = getEnumKeyByValue<AppFlavor>(
    AppFlavor,
    new URLSearchParams(location.search).get(CommonUrlQueryKeys.APP_FLAVOR) ?? undefined,
  )
  if (appFlavor === AppFlavor.PELLETTRACKER) {
    return 'pellet tracker'
  }
  return 'Fox'
}

export const getHardwareName = (): string => {
  const { location } = window
  const appFlavor = getEnumKeyByValue<AppFlavor>(
    AppFlavor,
    new URLSearchParams(location.search).get(CommonUrlQueryKeys.APP_FLAVOR) ?? undefined,
  )
  if (appFlavor === AppFlavor.PELLETTRACKER) {
    return 'pellet tracker'
  }
  return 'FoxHardware'
}

export class Language {
  constructor(public code: LanguageCode) {}

  private getI18nLanguageNameByLanguageCode(code: LanguageCode): string | undefined {
    const { langCode } = getLanguageConfig()
    return languages.getName(code, langCode)
  }

  get i18nName(): string | undefined {
    return this.getI18nLanguageNameByLanguageCode(this.code)
  }
}
