import type { StrictImageType } from 'shared/src/components/imageUpload/types'
import { BaseImmerable } from 'shared/src/model/BaseImmerable'
import type { AppFlavor } from 'shared/src/model/common'
import { ValidField } from 'shared/src/util/ValidField'
import { dataURLtoFile } from 'shared/src/util/blobUtil'
import { T, getBooleanTranslationKey } from 'shared/src/util/translation'
import { isValue, isValueAndNotEmptyTrimmedString } from 'shared/src/util/typeGuard'
import type { LSSupportForm } from 'support_form/src/localStorage/LSSupportForm'
import { Category, categoryTranslationMap } from 'support_form/src/model/common'
import type { DtoSupportEmailRequest, SupportFormField } from 'support_form/src/pages/support/queries/supportFormDtos'
import { Validator } from 'support_form/src/util/validator'

interface SupportFormStateConstructor {
  hwid: string
  appFlavor: AppFlavor
  userEmail: string | undefined
  userId: string | undefined
  category: Category | undefined
  description: string | undefined
  refill: boolean | undefined
  fillLevel: string | undefined
  relocated: boolean | undefined
}

export class SupportFormState extends BaseImmerable {
  hwid: string
  appFlavor: AppFlavor
  userEmail: ValidField<string | undefined>
  userId: string | undefined
  category: ValidField<Category | undefined>
  description: ValidField<string | undefined>
  refill: ValidField<boolean | undefined>
  fillLevel: ValidField<string | undefined>
  relocated: ValidField<boolean | undefined>
  images: StrictImageType[]

  constructor({
    hwid,
    appFlavor,
    userEmail,
    userId,
    category,
    description,
    refill,
    fillLevel,
    relocated,
  }: SupportFormStateConstructor) {
    super()
    this.hwid = hwid
    this.appFlavor = appFlavor
    this.userEmail = new ValidField<string | undefined>(userEmail, [
      Validator.StringOrUndefined.notEmpty,
      Validator.StringOrUndefined.email,
    ])
    this.userId = userId
    this.category = new ValidField<Category | undefined>(category, [Validator.CategoryOrUndefined.notEmpty])
    this.description = new ValidField<string | undefined>(description, [Validator.StringOrUndefined.notEmpty])
    this.refill = new ValidField<boolean | undefined>(refill, [Validator.BooleanOrUndefined.notEmpty])
    this.fillLevel = new ValidField<string | undefined>(fillLevel, [Validator.StringOrUndefined.notEmpty])
    this.relocated = new ValidField<boolean | undefined>(relocated, [Validator.BooleanOrUndefined.notEmpty])
    this.images = []
  }

  static fromLocalStorageType(hwid: string, sf: LSSupportForm): SupportFormState {
    return new SupportFormState({
      hwid,
      appFlavor: sf.appFlavor,
      userEmail: sf.userEmail,
      userId: sf.userId,
      category: sf.category,
      description: sf.description,
      refill: sf.refill,
      fillLevel: sf.fillLevel,
      relocated: sf.relocated,
    })
  }

  hasChanges(): boolean {
    return (
      isValue(this.category.value) ||
      isValue(this.refill.value) ||
      isValue(this.relocated.value) ||
      isValueAndNotEmptyTrimmedString(this.description.value) ||
      isValue(this.fillLevel.value)
    )
  }

  toLocalStorageType(): LSSupportForm {
    return {
      appFlavor: this.appFlavor,
      userEmail: this.userEmail.value,
      userId: this.userId,
      category: this.category.value,
      description: this.description.value,
      refill: this.refill.value,
      fillLevel: this.fillLevel.value,
      relocated: this.relocated.value,
    }
  }

  toDto(): DtoSupportEmailRequest {
    const fields: SupportFormField[] = []
    if (isValue(this.category.value)) {
      fields.push({ label: T('category_label'), value: T(categoryTranslationMap[this.category.value]) })
    }

    if (isValue(this.refill.value)) {
      fields.push({
        label: T('recent_refill_label'),
        value: T(getBooleanTranslationKey(this.refill.value)),
      })
    }

    if (isValue(this.fillLevel.value)) {
      fields.push({ label: T('expected_fill_level_label'), value: this.fillLevel.value.toString() })
    }

    if (isValue(this.relocated.value)) {
      fields.push({
        label: T('location_changed_label'),
        value: T(getBooleanTranslationKey(this.relocated.value)),
      })
    }

    return {
      hwid: this.hwid,
      body: {
        appFlavor: this.appFlavor,
        userEmail: this.userEmail.value,
        userId: this.userId,
        variables: {
          supportForm: {
            description: this.description.value ?? '',
            fields,
          },
        },
      },
      pictures: this.images.map((it) => ({
        name: it.file.name,
        bytes: dataURLtoFile(it.dataURL, it.file),
      })),
    }
  }

  validFields() {
    switch (this.category.value) {
      case Category.WRONG_FILL_LEVEL_MEASURED:
        return [this.userEmail, this.category, this.description, this.refill, this.fillLevel]
      case Category.DEVICE_IS_OFFLINE:
        return [this.userEmail, this.category, this.description, this.relocated]
      case undefined:
        return [this.userEmail, this.category, this.description, this.refill, this.fillLevel, this.relocated]
      default:
        return [this.userEmail, this.category, this.description]
    }
  }
}
