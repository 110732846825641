import * as dayjs from 'dayjs'
import 'dayjs/locale/de'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/fr'

import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isToday from 'dayjs/plugin/isToday'
import isoWeek from 'dayjs/plugin/isoWeek'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

export const initializeDayJS = () => {
  dayjs.extend(customParseFormat)
  dayjs.extend(duration)
  dayjs.extend(isSameOrAfter)
  dayjs.extend(isSameOrBefore)
  dayjs.extend(isoWeek)
  dayjs.extend(localeData)
  dayjs.extend(localizedFormat)
  dayjs.extend(relativeTime)
  dayjs.extend(isToday)
}
