import { Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import { useState } from 'react'
import { BannerType, FoxBanner } from 'shared/src/components/banner/FoxBanner'
import { FoxTextH4 } from 'shared/src/components/typography/FoxText'
import { AppFlavor } from 'shared/src/model/common'
import { setFoxColors } from 'shared/src/theme/foxColor'
import { Environment } from 'shared/src/util/environment'
import { T } from 'shared/src/util/translation'
import { getEnumKeyByValue } from 'shared/src/util/typeChecks'
import { isValue } from 'shared/src/util/typeGuard'
import { CommonUrlQueryKeys } from 'shared/src/util/url'
import { URLQueryKeys } from 'support_form/src/model/common'
import { SupportPage } from 'support_form/src/pages/support'
import { useUrlSearchParams } from 'support_form/src/util/hook'
import 'support_form/src/App.scss'

const UnsupportedRequestPage = () => {
  return (
    <div className='App'>
      <Typography variant='h1' component='div' gutterBottom>
        {T('unsupported_request')}
      </Typography>
    </div>
  )
}

const SuccessPage = () => {
  return (
    <div className='App'>
      <div className='with-margin'>
        <FoxBanner type={BannerType.SUCCESS} content={T('request_was_successful')} />
        <FoxTextH4>{T('page_close_return_app')}</FoxTextH4>
      </div>
    </div>
  )
}

const App = () => {
  const { urlSearchParams } = useUrlSearchParams()
  setFoxColors()
  const hwid = urlSearchParams.get(CommonUrlQueryKeys.HWID)?.toUpperCase()
  const appFlavor = getEnumKeyByValue<AppFlavor>(
    AppFlavor,
    urlSearchParams.get(CommonUrlQueryKeys.APP_FLAVOR) ?? undefined,
  )
  const environment = getEnumKeyByValue<Environment>(
    Environment,
    urlSearchParams.get(CommonUrlQueryKeys.ENV) ?? undefined,
  )
  const lang = urlSearchParams.get(URLQueryKeys.LANG) ?? undefined

  const [successState, setSuccessState] = useState(false)

  if (!isValue(hwid) || !isValue(appFlavor) || !isValue(environment) || !isValue(lang)) {
    return <UnsupportedRequestPage />
  }

  if (successState) {
    return <SuccessPage />
  }

  return <SupportPage hwid={hwid} appFlavor={appFlavor} setSuccessState={() => setSuccessState(true)} />
}

export default Sentry.withProfiler(App)
