import warning from 'warning'

export const nullableBooleanStringToBoolean = (value: string): boolean | undefined => {
  if (value === String(undefined)) {
    return undefined
  }
  if (value === String(true)) {
    return true
  }
  if (value === String(false)) {
    return false
  }
  warning(false, `unrecognized boolean ${value} of type ${typeof value}`)
  return undefined
}
