import { immerable } from 'immer'
import type { ValidatorFunction } from 'shared/src/model/validator'
import { ValidField } from 'shared/src/util/ValidField'

export abstract class BaseImmerable {
  public [immerable] = true

  validateWithoutUpdatingValidationResult<F>(customValidator?: ValidatorFunction<F>) {
    return ValidField.validateWithoutUpdatingValidationResult(this.validFields(), customValidator)
  }

  validate<F>(customValidator?: ValidatorFunction<F>) {
    ValidField.validate(this.validFields(), customValidator)
  }

  isValid(): boolean {
    return this.validFields().every((it) => it.isValid())
  }

  isInvalid(): boolean {
    return !this.isValid()
  }

  isInvalidWithoutUpdatingValidationResult(): boolean {
    return this.validFields().some((it) => !it.validateWithoutUpdatingValidationResult())
  }

  resetValidationErrors() {
    this.validFields().forEach((it) => it.resetValidationError())
  }

  abstract validFields(): ValidField<any>[]
}
