import type { ImageType } from 'react-images-uploading'

export interface StrictImageType extends ImageType {
  dataURL: string
  file: File
}

export interface PictureUpload {
  name: string
  bytes: Blob
}

export enum FileSizes {
  SIZE_512_KB = 512000,
  SIZE_1_MB = 1024000,
  SIZE_2_MB = 2048000,
  SIZE_4_MB = 4096000,
}
