import { Grid, Typography } from '@mui/material'
import { T } from 'shared/src/util/translation'

export const SupportHeader = () => {
  return (
    <Grid container justifyContent='center'>
      <Grid item>
        <Typography variant='h2' component='div' marginTop='1em' marginBottom='1em'>
          {T('contact_support')}
        </Typography>
      </Grid>
    </Grid>
  )
}
