import { isValueAndNotEmptyTrimmedString } from 'shared/src/util/typeGuard'

export const composeClassName = (baseClassName = '', ...classNames: (string | undefined)[]) => {
  return classNames.reduce((acc: string, it) => {
    if (isValueAndNotEmptyTrimmedString(it)) {
      return `${acc} ${it}`
    }
    return acc
  }, `${baseClassName}`)
}
